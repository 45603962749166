/* clears the 'X' from Internet Explorer */

html,
body {
  width: 100%;
  height: 100%;
}

input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

a:not(:hover),
a:not(:focus) {
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: underline;
}

a {
  color: #ff5000;
}

.score-primary {
  color: #16a085;
}
.score-warning {
  color: #ffb800;
}
.score-error {
  color: #df2a2a;
}
.dot {
  width: 10px;
  height: 8px;
  border-radius: 50%;
  margin: 0px 5px;
}
.positive__review--dot {
  background-color: #16a085;
}
.negative__review--dot {
  background-color: #df2a2a;
}

#headlessui-portal-root #modal {
  padding: 1.5rem 2rem !important;
}

#new-demo-request-modal #modal,
#onboarding-call-modal #modal,
#creative-call-modal #modal {
  width: auto;
}

.overflow-wrap {
  overflow-wrap: break-word;
}

@media screen and (max-width: 768px) {
  #new-demo-request-modal {
    display: flex;
  }
  #new-demo-request-modal #modal {
    width: 100%;
  }
}

.fade-out-animation {
  opacity: 1;
  animation-name: fade-out;
  animation-duration: 4s;
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fontSizeUpdate {
  font-size: 20px;
  padding-bottom: 10px;
  font-weight: 1000;
}

.achRoundedRectanlge{
  width: 200px;
  height: 100px;
  background-color: #5378FC;
  color: white;
  display:flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 500;
  border-radius: 30px;
  text-align: center;
  padding: 10px;
}	  